import moment from "moment"
import "moment/dist/locale/fr"
import momentDurationFormatSetup from "moment-duration-format"

const updateMomentLocales = (moment) => {
  moment.updateLocale("fr", {
    durationLabelsStandard: {
      S: "milliseconde",
      SS: "millisecondes",
      s: "seconde",
      ss: "secondes",
      m: "minute",
      mm: "minutes",
      h: "hour",
      hh: "hours",
      d: "jour",
      dd: "jours",
      w: "semaine",
      ww: "semaines",
      M: "mois",
      MM: "mois",
      y: "année",
      yy: "années",
    },
    durationLabelsShort: {
      S: "msec",
      SS: "msec",
      s: "sec",
      ss: "sec",
      m: "min",
      mm: "min",
      h: "h",
      hh: "h",
      d: "j",
      dd: "j",
      w: "sem",
      ww: "sem",
      M: "mo",
      MM: "mo",
      y: "a",
      yy: "a",
    },
    durationTimeTemplates: {
      HMS: "h:mm:ss",
      HM: "h:mm",
      MS: "m:ss",
    },
    durationLabelTypes: [
      { type: "standard", string: "__" },
      { type: "short", string: "_" },
    ],
    durationPluralKey: function (token, integerValue, decimalValue) {
      // Singular for a value of `1`, but not for `1.0`.
      if (integerValue === 1 && decimalValue === null) {
        return token
      }

      return token + token
    },
  })

  moment.updateLocale("en", {
    durationLabelsShort: {
      SS: "msec",
      ss: "sec",
      mm: "min",
      hh: "h",
      dd: "j",
      ww: "sem",
      MM: "mo",
      yy: "a",
    },
  })

}

export default {
  install(Vue) {
    momentDurationFormatSetup(moment)
    Vue.prototype.$moment = moment


    Vue.mixin({
      beforeCreate() {
        updateMomentLocales(this.$moment)
      },
      watch: {
        // watch i18n locale changes and update moment locale
        // This a dirty hack to make moment locale reactive,
        // but it seems to be the only working solution
        "$i18n.locale": {
          handler: function (newLocale, oldLocale) {
            if (newLocale === oldLocale) return

            this.$moment.locale(newLocale)
          },
          immediate: true,
        },
      },
    })
  },
}
